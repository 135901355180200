.campaigns-detail {
}

.campaigns-detail .pending {
  display: flex;
  align-items: center;
  margin-top: 40px;

}
.task-detail .box-wrapper {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.box-wrapper-c {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.campaign-task-step {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 22px 90px 22px 50px;

}


.campaign-task-step .center {
  margin-left: 30px;
}

.campaign-task-step .img-evidence{
  margin-top: 24px;
  position: relative;

}


.step-1-1 {
  display: flex;
  align-items: center;
}

.step-1 .content-de {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}
.step-1 .how-to-use{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.how-to-pro{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.mini-step-arrow{
  width: 12px;
  height: 7.5px;
  margin-left: 6px;
}

.step1-center {
  flex: 1;
}

.apply-btn-task-dis{
  width: 200px;
  height: 35px;
  border-radius: 40px;
  background-color: #eee;
  color: #fff;
  text-align: center;
  line-height: 35px;
  cursor: not-allowed;
  font-size: 15px;
}

.apply-btn-task {
  width: 170px;
  height: 45px;
  line-height: 45px;
  border-radius: 40px;
  background: linear-gradient(to right, #F79C37, #F0774A);
  color: #fff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  
}

.code-coupon {
  width: 208px;
  height: 86px;
  background: url('../../imgs/coupon.png') center no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.code-coupon .code-num {
  color: #F0774A;
  font-weight: bolder;
  font-size: 17px;
  font-family: 'RObotBold'!important;

}
.code-coupon .code-des {
  font-size: 12px;
  color: #aaa;
  transform: scale(.9);
}



.campaign-duration{
   font-size: 13px;
}
.campaign-duration .end-date {
  font-weight: bold;
}
.pending .task-st {
    width: 26px;
    height: 26px;

}
.pending .pend-txt {
  color: #f0774a;
  font-size: 14px;
  margin-left: 10px;
}

.campaigns-detail .status-des {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 23px;
}

.campaigns-detail .status-reject-reason{
  font-size: 12px;
  background-color : #FAEFE5;
  border: 1px solid #f6ded0;
  color: #a27758;
  margin-left: 15px;
  padding: 5px;  
  position: relative;

}
.status-reject-reason .reason-arrow{
      left: -9px;
      position: absolute;
      width: 9px;
      height: 11px;
      top: 50%;
      margin-top: -6.5px;
}
.status-reject-reason .ic-status-z {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.campaigns-detail .campaign-step {
   border: 1px solid #ddd;
   border-radius: 8px;
   margin-bottom: 20px;
   padding: 12px 10px 12px 10px; 
}

.step-i {
  display: flex;
}

.campaigns-detail .left {
  font-size: 20px;
  font-family: 'RobotItalic'!important;
  color: #f0774a;
  font-weight: bolder;
  white-space: nowrap;
}




.task-detail .detail-info{
  padding: 10px 25px 80px 32px;
}

.task-detail .duration-time{
   margin-left: 13px;
   font-weight: bold;
} 





 .img-evidence .evidence-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
  width: 170px;
  height: 170px;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.campaign-task-step .img-evidence .ant-upload.ant-upload-select{
   margin-top: 20px;
   margin-left: 10px;
}

.evidence-item-a {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* display: inline-block; */
  /* line-height: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.evidence-item-img {
  width: 100%;
  display: inline-block;
}

.evidence-item-img-video {
  width: 85px;
  height: 64px;
}

.del-icon {
  position: absolute;
  right: -5px;
  top: -5px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  cursor: pointer;
  display: none;
  z-index: 99;
}

.evidence-item:hover .del-icon {
  display: block;
}

.upload-evidence .ant-upload-list {
  display: none;
}

.upload-style {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: rgb(235, 235, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.upload-style .add-icon {
  font-size: 30px;
}

.upload-style .text {
  font-size: 14px;
}

.link-intr {
  font-size: 13px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 15px;
}
.zy-des {
  position: absolute;
  bottom: -35px;
  left: 0;
  display: flex;
  font-size: 12px;
  width: 200px;
}

.zy-des .zy-des-s{
  font-size: 12px;
  transform: scale(.9);
  margin-left: -10px;
}

.zy-des-link {
  position: unset;
  margin-top: 30px;
}
.zy-des .ic-zy {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.up-des{
  font-size: 12px;
  transform: scale(.9);
}

.link-cont {
  position: relative;
  padding-right: 40px;
  margin-bottom: 10px;
  width: 280px;
  height: 34px;
}

.link-input {
  font-size: 12px;
  padding: 0px 11px;
  height: 42px;
  width: 300px;
}

.del-input {
  position: absolute;
  right: 5px;
  top: 50%;
  /* transform: translateY(-50%); */
  margin-top: -4px;
  display: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.link-cont:hover .del-input {
  display: block;
}

.add-uri-btn {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.04) 0px 2px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 5px 0px;
    border: 1px solid #eee;
  background-color: #fff;
  color: #f0774a;
  font-size: 13px;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.handle-submit {
  font-size: 15px;
  color: #fff;
  background: linear-gradient(to right, #F79C37, #F0774A);
  padding: 12px 40px;
  text-align: center;
  border-radius: 100px;
  margin: 10px 0 60px 0;
  max-width: 400px;
  font-weight: bold;
}

.task-detail .detail-con {
}

.add-form .address-save-btn {
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}
.add-form .address-save-btn:hover{
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}

.add-form .ant-form-item-label{
  text-align: left;
}

.request-received {
  color: #f0774a;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .campaigns-detail .campaign-step {
    padding: 10px 10px 10px 10px;
  }

  .task-detail .box-wrapper{
    flex-direction: column;
    padding: 40px 15px 5px 15px;
  }

  .task-detail .detail-info{
    padding: 10px 5px 25px 0px;
  }
  .task-detail .box-wrapper{
    margin-bottom: 10px;
  }
  .task-detail  .pending{
    margin-top: 20px;
  }
  .campaigns-detail .status-reject-reason{
    padding: 2px 10px 2px 5px;
    transform: scale(.9);
  }

  .campaigns-detail .left {
    min-width: 50px;
    font-size: 19px;
  }

  .campaigns-detail .right {

  }

  .code-coupon .code-num{
    font-size: 16px;
  }
  .step-1 .content-de{
    font-size: 13px;
    margin-top: 0;
    white-space: nowrap;
  }

  .right .content-how {
    flex-direction: column;
    margin-top: 10px;
  }

  .right .apply-btn-task {
    padding: 4px 30px;
  }

  .right .how-to-use {
    margin-top: 5px;
  }

  .right .img-evidence {
    width: unset;
  }
  .campaigns-detail .status-des{
    margin-top: 10px;
  }
  .task-detail .box-wrapper-c{
    box-shadow: none;
    border: none;
    padding: 7px;
  }
  .campaign-task-step{
    border: none;
  }
  .task-detail .step-i{
    flex-direction: column;
    align-items: end;
  }

  .step-i .mobile-s-w{
    display: flex;
  }

  .campaign-task-step{
    padding: 5px 5px 5px 15px;
  }
  .campaign-task-step .center{
    margin-left: 0;
    width: 250px;
  }
  .code-coupon{
    width: 208px;
    /* height: 76px; */
  }
  .link-intr{
    margin-top: 20px;
  }

  .add-form .ant-form-item{
     margin-bottom: 0px;
  }
  .add-form .ant-form-item .ant-form-item-label{
    padding-bottom: 0;
  }
}