.campaigns-detail {
    position: relative;
    top: 93px;
}

.campaigns-detail .box-wrapper {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    display: flex;
    border-radius: 8px;
    padding: 32px 32px 100px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}

.detail-con {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 0px 100px 0px;
}

.detail-con .header-img {
    width: 400px;
}

.detail-con .header-img img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .14);
    cursor: pointer;

}

.detail-info {
    padding: 0 25px 80px 25px;
    position: relative;
    flex: 1 1;
}

.title {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 25px 0;
}

.title-text {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.title .like-it {
    cursor: pointer;
}

.title .like-it span {
    position: relative;
    top: -3px;
}

.title .liked {
    color: #f0774a;
}

.campaign-id {
    color: #999;
    font-size: 12px;
    margin-bottom: 10px;
}

.campaign-id-con {
    margin-left: 10px;
}

.campaign-type {
     color: #999;
    font-size: 12px;
    margin-bottom: 20px;
}

.type-con {
    margin-left: 10px;
}

.campaign-duration {
    font-size: 13px;
    margin-bottom: 10px;

}

.duration-time {
    margin-left: 10px;
}

.distribution-country {
    font-size: 13px;
    margin-bottom: 10px;
    display: flex;
}

.country-tag {
    font-size: 13px;
    margin-left: 10px;
}
.country-item .national-flag{
    width: 20px;
    border-radius: 100px;
}

.tag-item {
    padding: 1px 7px 1px 7px;
    font-size: 12px;
}

.free-sample {
    font-size: 13px;
    margin-bottom: 18px;
}
.ic-sample-icon{
   width: 18px;
   height: 18px;
   margin-left: 14px;
   margin-right: 10px;
   margin-top: -4px;
}  
.free-sam-yes {
    /* margin-left: 10px; */
    color: rgb(183,155,104);
}

.average-earn {
    font-size: 13px;
    margin-bottom: 10px;
    position: absolute;
}

.earn-money {
    color: #f0774a;
    margin-left: 10px;
    font-size: 24px;
    font-family: 'RObotBold'!important;
    font-weight: bold;
}

.earn-money .dollar {
    font-size: 18px;
}
.earn-money .money-num {
    font-weight: bold;
}

.box-wrapper .like-it {
    border: 1px solid #eee;
    border-radius: 50px;
    font-size: 14px;
    color: #999;
    display: inline-block;
    padding: 1px 20px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: 60px;
}


.like-it .anticon-heart {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.detail-con .liked .like-icon {
    color: #f0774a;
    /* border-color: #f0774a; */
}

.apply-now-btn {
    width: 172px;
    height: 57px;
    background: url(/static/media/ic_join.a8acb4c5.png) center no-repeat;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 10px;
    text-align: center;
    right: -18px;
    cursor: pointer;
}

.apply-now-btn-dis {
    width: 172px;
    height: 57px;
    background: url(/static/media/join_now_dis.4b72a7b9.png) center no-repeat;
    background-size: contain;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 40px;
    text-align: center;
    right: -18px;
    cursor: not-allowed;
}


.c-join-btn-w {
    display: flex;
    justify-content: center;
    align-items: center;

}

.c-join-now-btn {
    background: linear-gradient(to right, #F79C37, #F0774A);
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.c-join-now-btn-dis {
    background-color: #ccc;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}


.join-now {
    width: 168px;
    height: 53px;
    background: url(/static/media/ic_join.a8acb4c5.png);
    background-size: cover;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}

.join-now-dis {
    width: 168px;
    height: 53px;
    background: url(/static/media/join_now_dis.4b72a7b9.png);
    background-size: 168px 53px;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}




.campaign-step {
    display: flex;
    padding: 40px 0 0 0px;
    justify-content: center;
    align-items: center;

}

.box-wrapper-s {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: flex;
    border-radius: 8px;
    padding: 30px 25px 35px 0;
    margin-bottom: 30px;
}
.step-name {
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    position: relative;
}

.step-name .gray-line {
    width: 1px;
    height: 70%;
    border-right: 1px solid #ddd;
    right: 0;
    position: absolute;
}

.step-detail {
    display: flex;
    flex-direction: row;
    
}
.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 0 30px;
  position: relative;
  flex: 1 1;
}
.step-item .step-arrow {
    width: 26px;
    height: 15px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item  .step-arrow-b {
    width: 32px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item img {
    width: 50px;
    height: 50px;
}
.step-item .step-t {
   font-weight: bold;
   margin: 4px 0 4px 0;
   font-size: 14px;
}
.step-item .step-des {
   font-size: 13px;
   color: #aaa;
   text-align: center;
   height: 32px;
   margin-top: 10px;
}




.campaign-description {
    font-family: 'RObotBold'!important;
    padding: 40px 0 100px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
}

.campaign-description p {
    min-height: 20px;
    margin-bottom: 2px;
}

.campaign-duration img {
    max-width: 100%;
    max-height: 100%;
}

.des-title {
    font-weight: 500;
}

.des-content {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 13px;
}

.apply-btn-con {
    padding-bottom: 100px;
}

.apply-btn {
    display: block;
    background-color: #f0774a;
    color: #fff;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    margin: 30px auto 60px;

}


@media only screen and (max-width: 640px) {

    .title {
        margin: 18px 0 18px 0;
    }

    .des-content {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .campaigns-detail .box-wrapper{
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 30px;
        margin-bottom: 10px;
    }
    .detail-con .header-img{
        width: 100%;
        min-height: 180px;
    }
    .join-now-dis{
        right: 0;
    }
    .join-now{
        right: 0;
    }
    .box-wrapper-s{
        flex-direction: column;
        margin-bottom: 10px;
    }
    .step-detail{
        flex-direction: column;
    }
    .detail-info{
        padding: 0 25px 80px 0px;
    }
    .title-text{
        font-size: 17px;
    }
    .campaigns-detail .campaign-duration{
        font-size: 13px;
    }
    .campaigns-detail .free-sample{
        margin-bottom: 10px;
    }
    .box-wrapper .like-it{
        bottom: 30px;
        left: 27px;
    }
    .join-now, .join-now-dis {
        width: 128px;
        height: 42px;
        font-size: 15px;
        line-height: 38px;
        bottom: 20px;
    }
    .c-join-now-btn{
        padding: 6px 60px;
    }

    .step-item .step-arrow{
        display: none;
    }
    .step-item .step-arrow-b{
        display: none;
    }
    .step-name{
        justify-content: unset;
        margin-left: 25px;
    }
    .step-name .gray-line{
        display: none;
    }
    .step-item{
        margin-bottom: 13px;
    }
}
.campaigns-detail {
}

.campaigns-detail .pending {
  display: flex;
  align-items: center;
  margin-top: 40px;

}
.task-detail .box-wrapper {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.box-wrapper-c {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 25px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #f4f4f4;
}

.campaign-task-step {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 22px 90px 22px 50px;

}


.campaign-task-step .center {
  margin-left: 30px;
}

.campaign-task-step .img-evidence{
  margin-top: 24px;
  position: relative;

}


.step-1-1 {
  display: flex;
  align-items: center;
}

.step-1 .content-de {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}
.step-1 .how-to-use{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.how-to-pro{
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
}
.mini-step-arrow{
  width: 12px;
  height: 7.5px;
  margin-left: 6px;
}

.step1-center {
  flex: 1 1;
}

.apply-btn-task-dis{
  width: 200px;
  height: 35px;
  border-radius: 40px;
  background-color: #eee;
  color: #fff;
  text-align: center;
  line-height: 35px;
  cursor: not-allowed;
  font-size: 15px;
}

.apply-btn-task {
  width: 170px;
  height: 45px;
  line-height: 45px;
  border-radius: 40px;
  background: linear-gradient(to right, #F79C37, #F0774A);
  color: #fff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  
}

.code-coupon {
  width: 208px;
  height: 86px;
  background: url(/static/media/coupon.bbc3e86c.png) center no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.code-coupon .code-num {
  color: #F0774A;
  font-weight: bolder;
  font-size: 17px;
  font-family: 'RObotBold'!important;

}
.code-coupon .code-des {
  font-size: 12px;
  color: #aaa;
  transform: scale(.9);
}



.campaign-duration{
   font-size: 13px;
}
.campaign-duration .end-date {
  font-weight: bold;
}
.pending .task-st {
    width: 26px;
    height: 26px;

}
.pending .pend-txt {
  color: #f0774a;
  font-size: 14px;
  margin-left: 10px;
}

.campaigns-detail .status-des {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 23px;
}

.campaigns-detail .status-reject-reason{
  font-size: 12px;
  background-color : #FAEFE5;
  border: 1px solid #f6ded0;
  color: #a27758;
  margin-left: 15px;
  padding: 5px;  
  position: relative;

}
.status-reject-reason .reason-arrow{
      left: -9px;
      position: absolute;
      width: 9px;
      height: 11px;
      top: 50%;
      margin-top: -6.5px;
}
.status-reject-reason .ic-status-z {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.campaigns-detail .campaign-step {
   border: 1px solid #ddd;
   border-radius: 8px;
   margin-bottom: 20px;
   padding: 12px 10px 12px 10px; 
}

.step-i {
  display: flex;
}

.campaigns-detail .left {
  font-size: 20px;
  font-family: 'RobotItalic'!important;
  color: #f0774a;
  font-weight: bolder;
  white-space: nowrap;
}




.task-detail .detail-info{
  padding: 10px 25px 80px 32px;
}

.task-detail .duration-time{
   margin-left: 13px;
   font-weight: bold;
} 





 .img-evidence .evidence-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px 0;
  width: 170px;
  height: 170px;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.campaign-task-step .img-evidence .ant-upload.ant-upload-select{
   margin-top: 20px;
   margin-left: 10px;
}

.evidence-item-a {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* display: inline-block; */
  /* line-height: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.evidence-item-img {
  width: 100%;
  display: inline-block;
}

.evidence-item-img-video {
  width: 85px;
  height: 64px;
}

.del-icon {
  position: absolute;
  right: -5px;
  top: -5px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  cursor: pointer;
  display: none;
  z-index: 99;
}

.evidence-item:hover .del-icon {
  display: block;
}

.upload-evidence .ant-upload-list {
  display: none;
}

.upload-style {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: rgb(235, 235, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.upload-style .add-icon {
  font-size: 30px;
}

.upload-style .text {
  font-size: 14px;
}

.link-intr {
  font-size: 13px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 15px;
}
.zy-des {
  position: absolute;
  bottom: -35px;
  left: 0;
  display: flex;
  font-size: 12px;
  width: 200px;
}

.zy-des .zy-des-s{
  font-size: 12px;
  transform: scale(.9);
  margin-left: -10px;
}

.zy-des-link {
  position: unset;
  margin-top: 30px;
}
.zy-des .ic-zy {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.up-des{
  font-size: 12px;
  transform: scale(.9);
}

.link-cont {
  position: relative;
  padding-right: 40px;
  margin-bottom: 10px;
  width: 280px;
  height: 34px;
}

.link-input {
  font-size: 12px;
  padding: 0px 11px;
  height: 42px;
  width: 300px;
}

.del-input {
  position: absolute;
  right: 5px;
  top: 50%;
  /* transform: translateY(-50%); */
  margin-top: -4px;
  display: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.link-cont:hover .del-input {
  display: block;
}

.add-uri-btn {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.04) 0px 2px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 5px 0px;
    border: 1px solid #eee;
  background-color: #fff;
  color: #f0774a;
  font-size: 13px;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.handle-submit {
  font-size: 15px;
  color: #fff;
  background: linear-gradient(to right, #F79C37, #F0774A);
  padding: 12px 40px;
  text-align: center;
  border-radius: 100px;
  margin: 10px 0 60px 0;
  max-width: 400px;
  font-weight: bold;
}

.task-detail .detail-con {
}

.add-form .address-save-btn {
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}
.add-form .address-save-btn:hover{
  width: 100%;
  background: linear-gradient(to right, #F79C37, #F0774A);
  border-radius: 5px;
  color: #fff;
  border:none;
}

.add-form .ant-form-item-label{
  text-align: left;
}

.request-received {
  color: #f0774a;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .campaigns-detail .campaign-step {
    padding: 10px 10px 10px 10px;
  }

  .task-detail .box-wrapper{
    flex-direction: column;
    padding: 40px 15px 5px 15px;
  }

  .task-detail .detail-info{
    padding: 10px 5px 25px 0px;
  }
  .task-detail .box-wrapper{
    margin-bottom: 10px;
  }
  .task-detail  .pending{
    margin-top: 20px;
  }
  .campaigns-detail .status-reject-reason{
    padding: 2px 10px 2px 5px;
    transform: scale(.9);
  }

  .campaigns-detail .left {
    min-width: 50px;
    font-size: 19px;
  }

  .campaigns-detail .right {

  }

  .code-coupon .code-num{
    font-size: 16px;
  }
  .step-1 .content-de{
    font-size: 13px;
    margin-top: 0;
    white-space: nowrap;
  }

  .right .content-how {
    flex-direction: column;
    margin-top: 10px;
  }

  .right .apply-btn-task {
    padding: 4px 30px;
  }

  .right .how-to-use {
    margin-top: 5px;
  }

  .right .img-evidence {
    width: unset;
  }
  .campaigns-detail .status-des{
    margin-top: 10px;
  }
  .task-detail .box-wrapper-c{
    box-shadow: none;
    border: none;
    padding: 7px;
  }
  .campaign-task-step{
    border: none;
  }
  .task-detail .step-i{
    flex-direction: column;
    align-items: end;
  }

  .step-i .mobile-s-w{
    display: flex;
  }

  .campaign-task-step{
    padding: 5px 5px 5px 15px;
  }
  .campaign-task-step .center{
    margin-left: 0;
    width: 250px;
  }
  .code-coupon{
    width: 208px;
    /* height: 76px; */
  }
  .link-intr{
    margin-top: 20px;
  }

  .add-form .ant-form-item{
     margin-bottom: 0px;
  }
  .add-form .ant-form-item .ant-form-item-label{
    padding-bottom: 0;
  }
}
/* Make clicks pass-through */
#web-progress {
  pointer-events: none;
}

#web-progress .bar {
  background: #f0774a;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#web-progress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #f0774a, 0 0 5px #f0774a;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#web-progress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: calc(50% - 9px);
}

#web-progress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #f0774a;
  border-left-color: #f0774a;
  border-radius: 50%;

  -webkit-animation: web-progress-spinner 400ms linear infinite;
  animation: web-progress-spinner 400ms linear infinite;
}

.web-progress-custom-parent {
  overflow: hidden;
  position: relative;
}

.web-progress-custom-parent #web-progress .spinner,
.web-progress-custom-parent #web-progress .bar {
  position: absolute;
}

@-webkit-keyframes web-progress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes web-progress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@charset "utf-8";

/*!
 * @名称：base.css
 * @功能：1、重设浏览器默认样式
 *       2、设置通用原子类
 */
/* 防止用户自定义背景颜色对网页的影响，添加让用户可以自定义字体 */
html {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.44em;
}

/* 内外边距通常让各个浏览器样式的表现位置不同 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

/* 要注意表单元素并不继承父级 font 的问题 */
body,
button,
input,
select,
textarea {
    font: 14px \5b8b\4f53, arial, sans-serif;
}

input,
select,
textarea {
    font-size: 100%;
}

/* 去掉 table cell 的边距并让其边重合 */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ie bug：th 不继承 text-align */
th {
    text-align: inherit;
}

/* 去除默认边框 */
fieldset,
img {
    border: none;
}

/* ie6 7 8(q) bug 显示为行内表现 */
iframe {
    display: block;
}

/* 去掉 firefox 下此元素的边框 */
abbr,
acronym {
    border: none;
    font-feature-settings: normal;
    font-variant: normal;
}

/* 一致的 del 样式 */
del {
    text-decoration: line-through;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
    font-style: normal;
    font-weight: 500;
}

/* 去掉列表前的标识，li 会继承 */
ol,
ul {
    list-style: none;
}

/* 对齐是排版最重要的因素，别让什么都居中 */
caption,
th {
    text-align: left;
}

/* 来自yahoo，让标题都自定义，适应多个系统应用 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 500;
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

q:before,
q:after {
    content: '';
}

/* 统一上标和下标 */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

a:hover {
    text-decoration: underline;
}

/* 默认不显示下划线，保持页面简洁 */
ins,
a {
    text-decoration: none;
}

/* 去除 ie6 & ie7 焦点点状线 */
a:focus,
*:focus {
    outline: none;
}

/* 清除浮动 */
.clearfix:before,
.clearfix:after {
    content: '';
    display: block;
}

.clearfix:after {
    clear: both;
    overflow: hidden;
}

.clearfix {
    zoom: 1;
    /* for ie6 & ie7 */
}

.clear {
    clear: both;
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
}

/* 设置显示和隐藏，通常用来与 js 配合 */
.hide {
    display: none;
}

.block {
    display: block;
}

/* 设置浮动，减少浮动带来的 bug */
.fl,
.fr {
    display: inline;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.mr15 {
    margin-right: 15px;
}

.flex {
    display: flex;
}

.pointer {
    cursor: pointer;
}

.mr15 .info {}

.info .user {
    line-height: 38px;
}

.info .grade {
    line-height: 12px;
    display: flex;
    white-space: nowrap;
}

.grade .ant-progress-line {
    margin-top: -6px;
}

.mission-handle .ant-input-number::after {
    content: '%';
    position: absolute;
    right: -25px;
    width: 10px;
    height: 10px;
    top: 50%;
    margin-top: -7px;
}

.num-precent .ant-input-number::after {
    content: '%';
    position: absolute;
    right: -25px;
    width: 10px;
    height: 10px;
    top: 50%;
    margin-top: -7px;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    /* width: 5px; */
    background-color: #fff;
    height: 1px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}
/* @font-face {
  font-family: 'RobotoLight';
  src: url('./assets/font/Roboto-Light.ttf');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoNormal';
  src: url('./assets/font/Roboto-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./assets/font/Roboto-Bold.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
} */
 @font-face {
   font-family: 'Robot';
   src: url(/static/media/Roboto-Light.88823c20.ttf);
   font-weight: normal;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'RObotBold';
   src: url(/static/media/Roboto-Regular.11eabca2.ttf);
   font-weight: normal;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'RobotItalic';
   src: url(/static/media/Roboto-BoldItalic.5b44818d.ttf);
   font-weight: bold;
   font-style: italic;
   font-display: swap;
 }




html,body {
  margin: 0;
  padding: 0;
  font-family: 'Robot' !important;
  /* font-size: 1rem; */
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

::-webkit-scrollbar {
  height: 0px;
  width: 1px;

}
div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.point-class {
  cursor: pointer;
}

.ant-input {
  border-radius: 0;
}

.ant-btn {
  border-radius: 0;
}

.ant-btn-primary {
  background: #f0774a;
  border-color: #f0774a;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  /* background-color: #f9c9b7;
  border-color: #f9c9b7; */
  background: #f0774a;
  border-color: #f0774a;
}

.ant-input:hover {
  border-color: #f0774a;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-input-affix-wrapper:hover {
  border-color: #f0774a;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(240, 119, 74, 0.2);
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f0774a;
}

.ant-radio-checked::after {
  border: 1px solid #f0774a;
}

.ant-radio-inner::after {
  background-color: #f0774a;
}

.account-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f0774a;
  border-color: #f0774a;
  border-radius: 100px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f0774a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: #f0774a;
}

.ant-menu-submenu-selected{
  color: #000;
}
.ant-menu-item-selected{
  color: #f0774a;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #fff;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover{
  color: #f0774a;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  /* border: none; */
  border-color: #f0774a;
}
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
  background:#f0774a;
}
.ant-menu{
  font-size: 12px;
}
a:active,
a:hover {
  color: #f0774a;
}
.ant-input-affix-wrapper:hover{
  border-color: #d9d9d9;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  border-color: #d9d9d9;
}
/* .ant-pagination-item-active a {
  color: #f0774a;
}

.ant-pagination-item-active {
  border-color: #f0774a;
} */

.ant-form-item-label>label::after{
  content: '';
}
.ant-form-item-label>label{
  font-size: 13px;
}

.app .header {
  background-color: #fff;
  padding: 0;
  height: unset;
}
.header-white {
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}
.header-black .contact-us {
  background-color: rgb(30,26,27);
  color: #fff;
}

.header-white .contact-us {
  background-color: #fff;
}

.contact-us .contact-body {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: flex-end;
}
.header-white-logo {
  height: 20px;
}

.contact-us .select-container {
  display: inline-block;
  padding: 0;
  position: relative;
}

.contact-email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.con-login-singup{
  display: none;
}

.contact-email .contact-e {
  width: 17px;
  margin-right: 8px;
}
.contact-email .contact-txt{
  transform: scale(.93);
}

.contact-us .select-container select {
  border: none;
  background-color: rgb(234,232,232);
  position: auto;
  height: 100%;
  padding: 8px 35px;
  appearance: none;
  -moz-appearance: none;
  text-align: center;
  -webkit-appearance: none;
  transform: scale(.93);
}

.header-black .select-container select {
  background-color: rgb(64, 60, 61);
}

.contact-us .select-container .selectTri {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(155,155,155);
}

.contact-us .anticon-mail {
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}

.header .header-account {
  max-width: 1000px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 15px 18px 15px 18px;
}

.header-account .logo-img {
  width: 170px;
}

.header-account .logo-img img {
  width: 100%;
}

.user-header .my-account {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-account .header-arrow {
  display: none;
}
.my-account .nav-header-d{
  display: flex;
  align-items: center;
}


.my-account .header-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.my-account .account-email{
  font-size: 12px; 
  transform: scale(.94);
}
.my-account .ant-dropdown-link{
  color: #f0774a;
}

.main-logo {
  height: 40px;
  margin-right: 40px;
  cursor: pointer;
}

.nav-bar {
  /* background-color: rgb(249, 249, 249); */
  box-shadow: 0px 0px 13.5px rgba(0, 0, 0, .1);
  background-color: #fff;
}

.nav-bar .nav-con {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-con .nav-menu {
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: #fff;

}

.nav-con .nav-item {
  color: #000;
  width: 150px;
  padding: 20px 0;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  font-family: 'RObotBold'!important;
}


.login-signup {
   cursor: pointer;
}
.login-signup .login-s {
  color: #f0774a;
  font-size: 14px;
  font-weight: bold;
  font-family: 'RObotBold' !important;
}
.login-signup .gray-line {
  margin: 0 10px;
  height: 20px;
  display: inline-block;
  border-right: 1.5px solid #999;
  vertical-align: middle;
}
.login-signup .signup-s {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  font-family: 'RObotBold' !important;
}




.nav-con .nav-item-active {
  color: #f0774a;
  position: relative;

}
#messageDot::after{
  content: '';
  width: 6px;
  height: 6px;
  background-color: #f0774a;
  border-radius: 50%;
  display: inline-block;
  top: 20px;
  position: absolute;
}


.loading {
  text-align: center;
  color: #f0774a;
}

.loading .ant-spin-dot-item {
  background-color: #f0774a;
}
.img-evidence .ant-spin {
  color: #f0774a;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.ant-select-item{
  font-size: 13px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(240, 119, 74, .2)
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f0774a;
  box-shadow: 0 0 0 2px rgba(240, 119, 74, .2)
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f0774a;
}

.hide {
  display: none;
}

.ant-layout{
  background-color: #fff;

}


.app-footer {
  z-index: 9999;
  
}
.app-footer .footer-gray {
  background-color: rgb(245,242,240);
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  bottom: -10px;
}
.app-footer .footer-black {
  background-color: rgb(24,29,21);
  text-align: center;
  color: #fff;
  padding: 20px 0 10px 0;
  font-size: 12px;
  border-bottom: 2px solid #9C5136;
}
.footer-gray .footer-g-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}
.footer-g-content .cont-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-left .logo-ab {
  height: 18px;
}
.cont-left .ic-sx {
  height: 30px;
  margin: 0 10px;
}
.cont-left .logo {
  height: 30px;
}
.cont-right {
  display: flex;
  align-items: center;
} 
.cont-right .follow-us {
  font-size: 14px;
  margin-right: 10px;
}
.cont-right .logo-fb {
   height: 35px;
   margin: 0 5px;
}
.cont-right .logo-google {
   height: 35px;
} 



.input-warning {
  border-color: #f0774a;
}
.input-warning-txt {
  color: #ff4d4f;
  font-size: 12px;
  transform: scale(.9);
  margin-left: -10px;
}








/**
  message nav
*/

.message-nav {
  /* width: 171px; */
  width: 220px;
  /* height: 540px; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  border: 1px solid #f4f4f4;
  margin-right: 10px;
  padding-top: 30px;
  max-height: 500px;
  border-radius: 8px;
  position: fixed;
}
.message-menu-list {

}

.message-menu-item {
  margin-bottom: 30px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'RObotBold' !important;
  position: relative;
  display: flex;
  align-items: center;
}
.m-item-active{
  color: #f0774a;
  border-right: 2px solid #f0774a;
}

.message-menu-item .m-menu-name {
  padding: 4px 15px 4px 35px;
}
.message-menu-item .m-nums {
  background-color: #f0774a;
  color: #fff;
  border-radius: 100px;
  padding: 0px 15px;
  font-size: 12px;
  transform: scale(.8);
  height: 20px;
  line-height: 20px;
}

.message-menu-item:hover{
  font-weight: bolder;
  /* color: #f0774a; */
  font-family: 'RObotBold' !important;
}







/**
 profile layout
*/
.profile-layout {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 89px;
  padding-top: 16px;
}
.profile-layout-l {
  margin-left: 220px;
}
.profile-layout-l >.ant-layout {
  margin-left: 10px;
}

.profile-nav {
  width: 220px;
  height: 660px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
  margin-right: 14px;
  border-radius: 12px;
  border-bottom: 1px solid #F4F0EE;
}
.profile-nav .ant-menu-submenu-title{
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
}

.profile-nav .ant-menu-item{
  font-size: 13px;
  padding-left: 63px!important;
}

.profile-nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  font-weight: bold;
}

.profile-nav .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  height: 32px;
  margin-top: 4px;
}

.profile-nav .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left{
  border-right: none;
}
.profile-nav .ant-menu-submenu-arrow {
  display: none;
}

.nav-header {
   background-image: url('https://cdn.firstlinkapp.com/upload/2020_10/1603261927396_80930.jpg');
   background-size: cover;
   position: relative;
   width: 100%;
   /* min-height: 171px; */
   height: 220px;

   margin-bottom: 75px;

}

.edit-header{
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}


.edit-header .edit-header-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.nav-h-name {
  background: url(/static/media/nav_h_name.9008e587.png) center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 117px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.n-h-name {
   font-size: 19px;
   font-weight: bold;
   margin-bottom: 7px;
}
.n-h-email {
  font-size: 13px;
  color: #666;
}
.my-account-img {
  width: 22px;
  margin-right: 16px;
}
.my-earning-img {
  width: 22px;
  margin-right: 16px;
  vertical-align: sub;
}

.menu-w-i .arrow-down {
  width: 13px;
  height: 6px;
  position: absolute;
  right: 32px;
  top: 17px;

}
.heade-n-arrow{
  width: 15px;
  height: 15px;
  margin-left: 4px;
  cursor: pointer;
}

.my-account-img-1 {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}
.logout-img {
  width: 15px;
  margin-top: -4px;
  margin-right: 10px;
}

.war-title{
  font-weight: bold;
  border-left: 2px solid #f0774a;
  padding: 0px 10px;
}


.default-layout-app{
  /* padding-top: 110px; */
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon{
  color: #C6C6C6;
  display: none;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused{
  box-shadow: none;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
  box-shadow: none;
}
.ant-input:focus,
.ant-input-focused{
  box-shadow: none;
  border-color: #d9d9d9;
}
.ant-input:hover{
  border-color: #d9d9d9;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix{
  padding-right: 0;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon{
  right: -35px;
}


.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title{
  padding: 5px 55px 5px 20px;
}
.ant-dropdown-menu{
  border-radius: 6px;
  border: 1px solid #FAE8E1;
  padding: 10px 0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover{
      /* background-color: #fff; */
      /* border-left: 2px solid #f0774a; */
      color: #f0774a;
}
.ant-dropdown{
   
}
.ant-dropdown-placement-bottomRight>.ant-dropdown-arrow{
      right: 16px;
      border: 1px solid #FAE8E1;
      z-index: -1;
}

.ant-message{
  top: 90px;
}
.ant-message-notice-content{
  background-color: #999;
  opacity: .7;
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
}
.ant-message .anticon{
  display: none;
}

img{
  opacity: 1;
  transition: opacity .3s ease-in;
}


.ant-skeleton{
  margin-bottom: 10px;
}
.ant-skeleton-header{
  display: block;
  width: 360px!important;
  height: 200px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  border-radius: 0!important;
  width: 100%;
  height: 100%;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #FAE8E1;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color:#f0774a;
  box-shadow:none;
}



@media only screen and (max-width: 640px) {


  .contact-us .contact-body{
    justify-content: space-between;
  }
  .contact-body .contact-email {
    margin-left: 15px;
  }
  .contact-body .select-container {
    margin-right: 10px;
    padding: 2px 0 2px 0;
  }
  .select-container select {
    border-radius: 100px;
  }

  .header-account .logo-img {
    width: 120px;
  }

  .header-account .my-account {
    flex-direction: column;
    justify-content: center;
  }

  .my-account .account-email {
     font-weight: bold;
     transform: scale(.89);
  }

  .profile-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: 80px;
    padding-top: 0;
  }
  .profile-layout-l {
    margin-left: unset;
  }
  .profile-layout-l>.ant-layout {
    margin-left: unset;
  }

  .messages-layout {
    top: 125px;
  }

  .nav-bar {
    box-shadow: 0 0 black;
  }

  .nav-bar .nav-con{
    flex-direction: column;
    align-items: end;
    display: flex;
    flex-direction: column-reverse;

  }
  .nav-con .nav-menu{
    width: 100%;
    position: relative;
    top: -15px;
    border-top-right-radius: 56px;
    box-shadow: 0px -6px 10px 0px rgba(30, 25, 26, .09);
  }
  .nav-con .nav-menu[isaccount="true"] {
    top: 0;
  }

  .nav-con .my-profile {
    width: 100%;
    margin-top: 10px;
  }


  .nav-con .nav-menu img {
    display: none;
  }

  .profile-nav{
     width: 100%;
     height: unset;
     border-bottom: 10px solid #f4f4f4;
     position: unset;

  }
  .profile-nav .ant-menu{
    
  }
  .profile-nav .ant-menu-root {
    display: flex;
    position: relative;
    padding-bottom: 9px;
    overflow: hidden;
  }
  .profile-nav .ant-menu-root::before{
    content: '';
    height: 20px;
    border-right: 1px solid #999;
    position: absolute;
    left: 50%;
    top: 15px;
  }
  .profile-nav .ant-menu-submenu{
    flex: 1 1;
  }
  .profile-nav .ant-menu-item{
    padding-left: 30px!important;
  }
  .profile-nav .ant-menu-submenu-title{
    padding-left: 0px!important;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    text-align: center;

  }

  .profile-nav .menu-w-i .arrow-down{
    width: 10px;
    position: unset;
    margin-left: 4px;
  }

  .profile-nav .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after{
    border: none;
  }
  .profile-nav .ant-menu-sub.ant-menu-inline>.ant-menu-item,
  .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
  }

  .profile-nav .my-account-img{
    width: 18px;
    margin-right: 14px;
  }
  .profile-nav .my-earning-img{
    width: 18px;
    margin-right: 14px;
  }

  .profile-nav .my-earn-nav .arrow-down{
    margin-left: 7px;
  }

  .nav-header{
        width: 100px;
        margin: 12px auto 0;
        height: 100px;
        position: relative;
        margin-bottom: 60px;
  }
  .edit-header{
    left: -100px;
  }
  .default-layout-app{
    /* padding-top: 140px; */
  }

  .message-nav{
    margin-right: unset;
    width: 100%;
    padding-top: 0;
    box-shadow: 0px -5px 8px 0px rgba(30, 25, 26, .09);
    border: none;
    border-top-left-radius: 27px;
    border-top-right-radius: 0;
    position: fixed !important;
    z-index: 999;
    background-color: #fff;
  }
  .message-menu-list{
    overflow: hidden;
    padding: 10px 14px 10px 14px;
        height: 34px;
  }
  .m-over {
    display: flex;
    overflow-x: scroll;
    /* -webkit-overflow-scrolling: touch; */
  }
  .message-menu-list .m-item-active{
    border-right: none;
    position: relative;
    background-color: rgb(253,239,233);
    color: #f0774a;
    /* border-bottom: 2px solid #f0774a; */
  }
   /* .message-menu-list .m-item-active::after{
     width: 50px;
     height: 2px;
     content: '';
     display: block;
     background-color: #f0774a;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     bottom: -4px;

   } */

   .message-menu-item:hover {
     /* color: #666; */
   }


  .user-header .my-account{
    padding: 3px 10px 17px 10px;
    background-color: #E7D4CC;
    position: relative;
    /* top: 20px; */
    margin: 0px 15px 0 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: flex-start;

  }

  .my-account .header-arrow {
    width: 16px;
    position: absolute;
    right: 20px;
    display: block;
  }

  .my-account .header-img{
    width: 34px;
    height: 34px;
    border: 2px solid #F4EBE8;

  }

  .user-header .heade-n-arrow{
     display: none;
  }
  .message-menu-item{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    flex: 1 1;
    justify-content: center;
     border-top-left-radius: 14px;
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
  }
  .message-menu-item .m-menu-name{
    font-size: 12px;
    white-space: nowrap;
    transform: scale(.95);
    padding: 3px 10px 3px 10px;
   

  }
  .nav-h-name{
     height: 30px;
     background: none;
     bottom: -55px;
     width: 200px;
     left: 50%;
     margin-left: -100px;
  }
  .nav-con .nav-item{
    font-size: 13px;
    padding: 15px 0 0 0;
    position: relative;
  }
  .nav-con .nav-item::after {
    content: '';
    display: block;
    height: 20px;
    border-right: 1px solid #eae6e4;
    position: absolute;
    bottom: 0;
  }
  .nav-con .nav-item[first="true"]::after {
   display: none;
  }


  .ant-skeleton{
    width: 80%;
    display: block;
    margin: 0 auto 20px;
  }
  .ant-skeleton-header{
    width: 100%!important;
  }
  .ant-skeleton-content{
    display: block;
  }
  .ant-form label{
    line-height: 2;
  }

  .contact-us .select-container select{
    padding: 6px 30px;
  }
  .contact-email{
    margin-right: 10px;
  }






  .app-footer .footer-gray{
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
  }





  .footer-gray .footer-g-content{
    width: 100%;
  }
  .cont-left .logo-ab {
    height: 14px;
  }

  .cont-left .ic-sx {
    height: 14px;
    margin: 0 5px;
  }

  .cont-left .logo {
    height: 20px;
  }
  .cont-right .follow-us {
    font-size: 12px;
  }
  .cont-right .logo-fb {
    height: 25px;
    margin: 0 5px;
  }

  .cont-right .logo-google {
    height: 25px;
  }




  .login-signup {
    cursor: pointer;
    margin: 10px 0 5px 5px;
  }

  .login-signup .login-s {
    color: #f0774a;
    font-size: 12px;
    font-weight: bold;
    font-family: 'RObotBold' !important;
  }

  .login-signup .gray-line {
    margin: 0 10px;
    height: 17px;
    display: inline-block;
    border-right: 1.5px solid #999;
    vertical-align: middle;
  }

  .login-signup .signup-s {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-family: 'RObotBold' !important;
  }

  .con-login-singup{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px!important;
        cursor: pointer;
  }




  .message-menu-item .m-nums{
        min-width: 35px;
        padding: 0 6px;
        text-align: center;
  }

}
