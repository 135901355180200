.campaigns-detail {
    position: relative;
    top: 93px;
}

.campaigns-detail .box-wrapper {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .06);
    display: flex;
    border-radius: 8px;
    padding: 32px 32px 100px 25px;
    position: relative;
    margin-bottom: 26px;
    border: 1px solid #f4f4f4;
}

.detail-con {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 0px 100px 0px;
}

.detail-con .header-img {
    width: 400px;
}

.detail-con .header-img img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .14);
    cursor: pointer;

}

.detail-info {
    padding: 0 25px 80px 25px;
    position: relative;
    flex: 1;
}

.title {
    display: flex;
    justify-content: space-between;
    margin: 0px 0 25px 0;
}

.title-text {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.title .like-it {
    cursor: pointer;
}

.title .like-it span {
    position: relative;
    top: -3px;
}

.title .liked {
    color: #f0774a;
}

.campaign-id {
    color: #999;
    font-size: 12px;
    margin-bottom: 10px;
}

.campaign-id-con {
    margin-left: 10px;
}

.campaign-type {
     color: #999;
    font-size: 12px;
    margin-bottom: 20px;
}

.type-con {
    margin-left: 10px;
}

.campaign-duration {
    font-size: 13px;
    margin-bottom: 10px;

}

.duration-time {
    margin-left: 10px;
}

.distribution-country {
    font-size: 13px;
    margin-bottom: 10px;
    display: flex;
}

.country-tag {
    font-size: 13px;
    margin-left: 10px;
}
.country-item .national-flag{
    width: 20px;
    border-radius: 100px;
}

.tag-item {
    padding: 1px 7px 1px 7px;
    font-size: 12px;
}

.free-sample {
    font-size: 13px;
    margin-bottom: 18px;
}
.ic-sample-icon{
   width: 18px;
   height: 18px;
   margin-left: 14px;
   margin-right: 10px;
   margin-top: -4px;
}  
.free-sam-yes {
    /* margin-left: 10px; */
    color: rgb(183,155,104);
}

.average-earn {
    font-size: 13px;
    margin-bottom: 10px;
    position: absolute;
}

.earn-money {
    color: #f0774a;
    margin-left: 10px;
    font-size: 24px;
    font-family: 'RObotBold'!important;
    font-weight: bold;
}

.earn-money .dollar {
    font-size: 18px;
}
.earn-money .money-num {
    font-weight: bold;
}

.box-wrapper .like-it {
    border: 1px solid #eee;
    border-radius: 50px;
    font-size: 14px;
    color: #999;
    display: inline-block;
    padding: 1px 20px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: 60px;
}


.like-it .anticon-heart {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.detail-con .liked .like-icon {
    color: #f0774a;
    /* border-color: #f0774a; */
}

.apply-now-btn {
    width: 172px;
    height: 57px;
    background: url('../../imgs/ic_join.png') center no-repeat;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 10px;
    text-align: center;
    right: -18px;
    cursor: pointer;
}

.apply-now-btn-dis {
    width: 172px;
    height: 57px;
    background: url('../../imgs/join_now_dis.png') center no-repeat;
    background-size: contain;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    background-size: contain;
    position: absolute;
    bottom: 40px;
    text-align: center;
    right: -18px;
    cursor: not-allowed;
}


.c-join-btn-w {
    display: flex;
    justify-content: center;
    align-items: center;

}

.c-join-now-btn {
    background: linear-gradient(to right, #F79C37, #F0774A);
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.c-join-now-btn-dis {
    background-color: #ccc;
    border-radius: 100px;
    padding: 12px 150px;
    color: #fff;
    border: none;
    cursor: pointer;
}


.join-now {
    width: 168px;
    height: 53px;
    background: url('../../imgs/ic_join.png');
    background-size: cover;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}

.join-now-dis {
    width: 168px;
    height: 53px;
    background: url('../../imgs/join_now_dis.png');
    background-size: 168px 53px;
    position: absolute;
    right: -18px;
    bottom: 10px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

}




.campaign-step {
    display: flex;
    padding: 40px 0 0 0px;
    justify-content: center;
    align-items: center;

}

.box-wrapper-s {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: flex;
    border-radius: 8px;
    padding: 30px 25px 35px 0;
    margin-bottom: 30px;
}
.step-name {
    width: 160px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    position: relative;
}

.step-name .gray-line {
    width: 1px;
    height: 70%;
    border-right: 1px solid #ddd;
    right: 0;
    position: absolute;
}

.step-detail {
    display: flex;
    flex-direction: row;
    
}
.step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 0 30px;
  position: relative;
  flex: 1;
}
.step-item .step-arrow {
    width: 26px;
    height: 15px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item  .step-arrow-b {
    width: 32px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: -10px;
}

.step-item img {
    width: 50px;
    height: 50px;
}
.step-item .step-t {
   font-weight: bold;
   margin: 4px 0 4px 0;
   font-size: 14px;
}
.step-item .step-des {
   font-size: 13px;
   color: #aaa;
   text-align: center;
   height: 32px;
   margin-top: 10px;
}




.campaign-description {
    font-family: 'RObotBold'!important;
    padding: 40px 0 100px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
}

.campaign-description p {
    min-height: 20px;
    margin-bottom: 2px;
}

.campaign-duration img {
    max-width: 100%;
    max-height: 100%;
}

.des-title {
    font-weight: 500;
}

.des-content {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 13px;
}

.apply-btn-con {
    padding-bottom: 100px;
}

.apply-btn {
    display: block;
    background-color: #f0774a;
    color: #fff;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    margin: 30px auto 60px;

}


@media only screen and (max-width: 640px) {

    .title {
        margin: 18px 0 18px 0;
    }

    .des-content {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .campaigns-detail .box-wrapper{
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 30px;
        margin-bottom: 10px;
    }
    .detail-con .header-img{
        width: 100%;
        min-height: 180px;
    }
    .join-now-dis{
        right: 0;
    }
    .join-now{
        right: 0;
    }
    .box-wrapper-s{
        flex-direction: column;
        margin-bottom: 10px;
    }
    .step-detail{
        flex-direction: column;
    }
    .detail-info{
        padding: 0 25px 80px 0px;
    }
    .title-text{
        font-size: 17px;
    }
    .campaigns-detail .campaign-duration{
        font-size: 13px;
    }
    .campaigns-detail .free-sample{
        margin-bottom: 10px;
    }
    .box-wrapper .like-it{
        bottom: 30px;
        left: 27px;
    }
    .join-now, .join-now-dis {
        width: 128px;
        height: 42px;
        font-size: 15px;
        line-height: 38px;
        bottom: 20px;
    }
    .c-join-now-btn{
        padding: 6px 60px;
    }

    .step-item .step-arrow{
        display: none;
    }
    .step-item .step-arrow-b{
        display: none;
    }
    .step-name{
        justify-content: unset;
        margin-left: 25px;
    }
    .step-name .gray-line{
        display: none;
    }
    .step-item{
        margin-bottom: 13px;
    }
}